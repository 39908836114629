import React from "react";
import { Container, Image } from "react-bootstrap";
import SliceZone from "../../SliceZone";
import "../../../styles/components/Blog/Details/style.scss";

const formatDate = (input) => {
  const result = input.split("-");
  return `${result[1]}/${result[2]}/${result[0]}`;
}

const BlogDetailsComponent = ({
  title,
  subtitle,
  author,
  date,
  category,
  nextSlug,
  nextBlog,
  previousSlug,
  previousBlog,
  slices
}) => {
  return (
    <>
      <Container className="pt-80 pb-top-40 mb-140 px-0">
        <div className="d-lg-flex justify-content-between">
          <div>
            <h1 className="blog-details-title text-center text-md-start">
              <b>{title}</b>
            </h1>
            <p className="blog-details-subtitle text-center text-md-start">
              {subtitle}
            </p>
            <p className="blog-details-subtitle text-center text-md-start">
              {author}
            </p>
          </div>
          <div>
            <h4 className="blog-details-date-category text-center text-md-start">
              {date && formatDate(date)} | {category}
            </h4>
            <div className="blog-details-social d-flex justify-content-lg-end justify-content-center">
              <Image className="fb-icon" src="/assets/images/facebook.svg" />
              <Image
                className="twitter-icon"
                src="/assets/images/twitter.svg"
              />
              <Image
                className="linkedin-icon"
                src="/assets/images/linkedin.svg"
              />
              <Image className="link-icon" src="/assets/images/link.svg" />
            </div>
          </div>
        </div>

        <SliceZone sliceZone={slices} />

        <div className="pre-next-section d-lg-flex justify-content-lg-between">
          <div className="previous-section">
            {previousBlog && (
              <>
                <div className="" style={{ marginRight: "19px" }}>
                  <Image src="/assets/images/previous.svg" />
                </div>
                <div>
                  <p className="previous-text mb-0">Previous</p>
                  <p className="previous-post-text mb-0">
                    <a href={`/blog/${previousSlug}`}>
                      <b>{previousBlog}</b>
                    </a>
                  </p>
                </div>
              </>
            )}
          </div>
          <div className="next-section">
            {nextBlog && (
              <>
                <div>
                  <p className="next-text mb-0 text-end">Next</p>
                  <p className="next-post-text mb-0">
                    <a href={`/blog/${nextSlug}`}>
                      <b>{nextBlog}</b>
                    </a>
                  </p>
                </div>
                <div className="" style={{ marginLeft: "19px" }}>
                  <Image src="/assets/images/next.svg" />
                </div>
              </>
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

export default BlogDetailsComponent;
