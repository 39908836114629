import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { Row, Col, Button, Image } from "react-bootstrap";
import { client as prismicClient } from "../libs/prismic";
import Loader from "../components/Loader";
import { RichText } from "prismic-reactjs";
import "../styles/pages/about-us.scss";

const AboutUs = () => {
  const [aboutUsDoc, setAboutUsDoc] = useState();
  const [content, setContent] = useState();

  // useEffect(()=>{
  //   let content = aboutUsDoc?.data;
  //   setContent(content);

  //   if(!content) {
  //     return
  //   }

  //   function addLineBreak(line) {
  //     if(line.text === '' && line.type === 'paragraph') {
  //       line.text = '\n';
  //     }
  //   }

  //   content.banner_section.forEach(addLineBreak);
  //   content.left_section.forEach(addLineBreak);
  //   content.right_section.forEach(addLineBreak);

  //   setContent(content);
  // },[aboutUsDoc])

  useEffect(() => {
    async function fetchData() {
      
      // const response = await prismicClient().getSingle("about_us");
      const response = await prismicClient().getSingle("about_us2");
      setAboutUsDoc(response);
      
    }
    fetchData();
  }, []);

  function Mailto({ email, subject, body, ...props }) {
    return (
      <a href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
        {props.children}
      </a>
    );
  }
  
  return (aboutUsDoc && aboutUsDoc.data) ? (
    <Layout>
      <Row className="pt-80 pb-top-40 mw-1440 mx-auto justify-content-center padding-mobile">
        <Col md={12}>
          <div className="rich-text">
            <RichText render={aboutUsDoc.data?.banner_section} />
          </div>
        </Col>
      </Row>
      <Row className="mw-1440 mx-auto justify-content-center mb-80 padding-mobile">
        <Col md={12}>
          <div className="rich-text about-us-section">
            <RichText render={aboutUsDoc.data?.executive_team_title} /> 
            {aboutUsDoc.data?.executive_team.map((item) => (
              <div className="executive-div">
                {/* {item.member_image?.url !== undefined ? ( */}
                  <Image
                    className="member-icon img-fluid"
                    src={item.member_image?.url}
                    height={"100%"}
                  />
                {/* ) : (
                  <div className="member-icon img-fluid">
                  </div>
                )} */}
                <div className="executive-field">
                  <p className="member-name-label">{`${item.member_name[0].text}`}</p>
                  <p className="member-position-label">{`${item.member_position[0].text}`}</p>
                  <p className="member-description-label">{`${item.member_description[0].text}`}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="rich-text about-us-section">
            <RichText render={aboutUsDoc.data?.advisors_title} /> 
            {aboutUsDoc.data?.advisors.map((item) => (
              <div className="executive-div">
                <Image
                  className="member-icon"
                  src={item.advisor_image?.url}
                />
                <div className="executive-field">
                  <p className="member-name-label">{`${item.advisor_name[0].text}`}</p>
                  <p className="member-position-label">{`${item.advisor_position[0].text}`}</p>
                  <p className="member-description-label">{`${item.advisor_description[0].text}`}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="rich-text about-us-section">
            <RichText render={aboutUsDoc.data?.board_of_directors} /> 
          </div>

          <div className="rich-text about-us-section">
            <RichText render={aboutUsDoc.data?.locations_title} /> 
            {aboutUsDoc.data?.locations.map((item) => (
              <div className="executive-div">
                <div className="location-field">
                  <p className="member-name-label">{`${item.location_title[0].text}`}</p>
                  <p className="member-position-label">{`${item.location_type[0].text}`}</p>
                  {item.location_address.map((loc_address) => (
                    <p className="member-description-label">{loc_address.text}</p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </Col>
        {/* <Col md={2}>
          <div className="about-us-section-separator"></div>
        </Col>
        <Col md={5}>
          <div className="rich-text about-us-richtext">
            <RichText render={content?.right_section} />
          </div>
          <Button className="contact-button">
            <Mailto
              email="partners@opalvest.com"
              subject="Partner"
              body="Hello world!"
            >
              {content?.about_us_button_text[0]?.text}
            </Mailto>
          </Button>
        </Col> */}
      </Row>
    </Layout>
  ) : (
    <Loader />
  );
};

export default AboutUs;
