import React, { useEffect, useState } from "react";
import BlogDetailsComponent from "../../components/Blog/Details";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import { getDataByUID, getNextData, getPreviousData } from "../../libs/prismic";
import NotFoundPage from "../404";

const BlogDetails = ({ slug }) => {
  const [blogDoc, setBlogDoc] = useState();
  const [postDate, setPostDate] = useState();
  const [showErrorPage, setShowErrorPage] = useState(false);

  async function fetchData(uid) {
    try {
      const response = await getDataByUID("blog_post", uid);
      if (response) {
        setBlogDoc(response);
        setPostDate(response.first_publication_date);
      }
    } catch (error) {
      setShowErrorPage(true);
    }
  }

  async function fetchNextData(postDate) {
    try {
      const response = await getNextData("blog_post", postDate);
      if (response.results.length > 0) {
        setBlogDoc((prev) => ({
          ...prev,
          next: response.results[0],
        }));
      }
    } catch (error) {
      setShowErrorPage(true);
    }
  }

  async function fetchPreviousData(postDate) {
    try {
      const response = await getPreviousData("blog_post", postDate);
      if (response.results.length > 0) {
        setBlogDoc((prev) => ({
          ...prev,
          previous: response.results[0],
        }));
      }
    } catch (error) {
      setShowErrorPage(true);
    }
  }

  useEffect(() => {
    if (slug) {
      fetchData(slug);
    }
  }, [slug]);

  useEffect(() => {
    if (postDate) {
      fetchNextData(postDate);
      fetchPreviousData(postDate);
    }
  }, [postDate]);

  return blogDoc ? (
    <Layout>
      <BlogDetailsComponent
        slices={blogDoc.data?.body}
        title={blogDoc.data?.title?.[0]?.text}
        subtitle={blogDoc.data?.subtitle?.[0]?.text}
        author={blogDoc.data?.author?.[0]?.text}
        date={blogDoc.data?.date}
        category={blogDoc.data?.category}
        nextSlug={blogDoc.next?.uid}
        nextBlog={blogDoc.next?.data?.title?.[0]?.text}
        previousSlug={blogDoc.previous?.uid}
        previousBlog={blogDoc.previous?.data?.title?.[0].text}
      />
    </Layout>
  ) : showErrorPage ? (
    <NotFoundPage />
  ) : (
    <Loader />
  );
};

export default BlogDetails;
