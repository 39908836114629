import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { isMobile, numberWithCommas } from "../../../utils";
import { Tr, Td } from "../../../components/Table";
import { Image, Button, Dropdown } from "react-bootstrap";
import clsx from "clsx";

const TableRow = ({
  brand,
  opal,
  isParent,
  detailHandler = () => { },
  buyHandler,
  mediaHandler = () => { },
  title,
  shareOpalHandler = () => { },
  share = false,
  mode = 'invest'
}) => {
  if (process.env.NODE_ENV !== "test") {
    moment.tz.setDefault('America/New_York');
  }
  const parentAccounts = useSelector(
    (state) => state?.accounts?.parentAccounts
  );
  const opalRef = useRef();
  const strategyRef = useRef();
  const minimumRef = useRef();
  const [resizeKey, setResizeKey] = useState('');
  const [hasThumbnail, setHasThumbnail] = useState(false);
  const [mediaCat, setMediaCat] = useState('Category 1');
  const [selectedMediaCat, setSelectedMediaCat] = useState('Featured');
  const [showMobileDropdown, setShowMobileDropdown] = useState(false);

  const mediaCategoryHandler = (categoryValue, categoryString) => {
    setMediaCat(categoryValue);
    setSelectedMediaCat(categoryString);
  }

  const getAvailability = (opal) => {

    const begins = moment(opal.begins).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).valueOf();
    const ends = moment(opal.ends).set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).valueOf();
    const today = moment().valueOf();

    if (today > ends) {
      return isMobile() ? (
        `Ended ${moment(opal.ends).format("M/D/YY")}`
      ) : (
        <>
          Ended
          <br />
          {moment(opal.ends).format("M/D/YY")}
        </>
      );
    } else if (begins > today) {
      return isMobile() ? (
        `Begins ${moment(opal.begins).format("M/D/YY")}`
      ) : (
        <>
          Begins
          <br />
          {moment(opal.begins).format("M/D/YY")}
        </>
      );
    } else {
      return isMobile() ? (
        `Ends ${moment(opal.ends).format("M/D/YY")}`
      ) : (
        <>
          Ends
          <br />
          {moment(opal.ends).format("M/D/YY")}
        </>
      );
    }
  };

  const isAvailability = (opal) => {
    const begins = moment(opal.begins).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).valueOf();
    const ends = moment(opal.ends).set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).valueOf();
    const today = moment().valueOf();

    if (opal.capPercent >= 100) {
      return 3;
    }

    if (!opal.display || today > ends || begins > ends) {
      return 2;
    } else if (begins > today) {
      return 0;
    } else {
      return 1;
    }
  };

  const isBought = (opal, brand) => {
    if (parentAccounts.length > 0) {
      return parentAccounts?.some(row => row.transactions.some(el => el.opal_name === opal.opal_name && el.brand_uid === brand?.uid))
    } else {
      return false
    }

  }

  const getMaxCount = () => {
    const totoalCount = Math.max(getFirstCount(), getSecondCount(), getThirdCount());
    return totoalCount
  }

  const getFirstCount = () => {
    let count = 0;
    if (opal.file1?.url) {
      count++;
    }
    if (opal.file4?.url) {
      count++;
    }
    if (opal.file7?.url) {
      count++;
    }
    return count
  }

  const getSecondCount = () => {
    let count = 0;
    if (opal.file2?.url) {
      count++;
    }
    if (opal.file5?.url) {
      count++;
    }
    if (opal.file8?.url) {
      count++;
    }
    return count
  }

  const getThirdCount = () => {
    let count = 0;
    if (opal.file3?.url) {
      count++;
    }
    if (opal.file6?.url) {
      count++;
    }
    if (opal.file9?.url) {
      count++;
    }
    return count
  }

  useEffect(() => {
    if (window) {
      window.addEventListener("resize", onResize);

      return () => {
        window.removeEventListener("resize", onResize);
      };
    }
  }, []);

  useEffect(() => {
    if (opal) {
      if (opal.file_1_thumbnail?.url ||
        opal.file_2_thumbnail?.url ||
        opal.file_3_thumbnail?.url ||
        opal.file_4_thumbnail?.url ||
        opal.file_5_thumbnail?.url ||
        opal.file_6_thumbnail?.url ||
        opal.file_7_thumbnail?.url ||
        opal.file_8_thumbnail?.url ||
        opal.file_9_thumbnail?.url) {
        setHasThumbnail(true)
      }
      if (opal.file_1_category == null) opal.file_1_category = 'Category 1'
      if (opal.file_2_category == null) opal.file_2_category = 'Category 1'
      if (opal.file_3_category == null) opal.file_3_category = 'Category 1'
      if (opal.file_4_category == null) opal.file_4_category = 'Category 1'
      if (opal.file_5_category == null) opal.file_5_category = 'Category 1'
      if (opal.file_6_category == null) opal.file_6_category = 'Category 1'
      if (opal.file_7_category == null) opal.file_7_category = 'Category 1'
      if (opal.file_8_category == null) opal.file_8_category = 'Category 1'
      if (opal.file_9_category == null) opal.file_9_category = 'Category 1'

      if (opal.file_category_1_label == null ) opal.file_category_1_label = 'Featured'
      if (opal.file_category_2_label == null ) opal.file_category_2_label = 'Deep-Dive'
      if (opal.file_category_3_label == null ) opal.file_category_3_label = 'Reports'
    }
    // It's for hiding category dropdown in opal list page
    window.location.pathname.split("/")[2] !== "" ? setShowMobileDropdown(true) : setShowMobileDropdown(false);
  }, [opal]);

  const onResize = (e) => {
    setResizeKey((Math.random() + 1).toString(36).substring(7))
  }
  useEffect(() => {
    if (parentAccounts && opalRef && strategyRef && minimumRef) {
      let mxHeight = opalRef.current.offsetHeight > strategyRef.current.offsetHeight ? opalRef.current.offsetHeight : strategyRef.current.offsetHeight
      mxHeight = mxHeight > minimumRef.current.offsetHeight ? mxHeight : minimumRef.current.offsetHeight;
      opalRef.current.style.height = `${mxHeight}px`;
      strategyRef.current.style.height = `${mxHeight}px`;
      minimumRef.current.style.height = `${mxHeight}px`;
    }
  }, [parentAccounts, resizeKey, opalRef, strategyRef, minimumRef])

  const ria = parentAccounts?.[0]?.type === 'ria'

  return (
    <>
      <Tr
        className={clsx("d-none d-md-table",
          { "hover-table": isParent })}
        key={`${opal.opal_name}${opal.begins}${opal.end}`}
        title={title}
        onClick={() => detailHandler(brand.uid, opal.opal_name)}
      >
        <Td className="">
          <div className="invester-div">
            <Image
              className="edit-icon"
              src={opal.opal_image.url}
            />
            <div className="invester-field" ref={opalRef}>
              <p className="name-label">{`${opal.opal_name}`}</p>
              <p>
                {`${opal.fund_type || ""} | ${opal.opal_highlight_label
                  }`}{" "}
                <span
                  className={
                    opal.gain_loss ? "text-green" : "text-red"
                  }
                >{`${opal.gain_loss ? "+" : "-"} ${opal.percentage
                  }%`}</span>
              </p>
            </div>
          </div>

        </Td>
        {isParent && <Td>{brand.data?.brand_image_title?.[0]?.text}</Td>}
        <Td>
          <div className="content-div">
            <div 
              className="strategy-div" 
              ref={strategyRef} 
              style={{ maxWidth: isParent ? '300px' : 'none', display: isParent ? '-webkit-box' : 'block' }} 
            >
              {console.log("opal.strategy1", opal.strategy1)}
              {opal.strategy1.map(
                (item, index) => (
                  <p key={index} style={{ marginBottom: "10px" }}>{item.text}</p>
                ))}
            </div>
          </div>

        </Td>
        <Td className="text-right">
            {/* <div ref={minimumRef} style={{ visibility: mode === 'invest' ? 'visible' : 'hidden', display: 'inline-table' }}> */}
            <div ref={minimumRef} style={{ display: 'inline-table' }}>
              <div>
                $
                {numberWithCommas(parseInt(opal.unit_price) *
                  parseInt(opal.minimum_units), false)}
              </div>
              <div>
                {numberWithCommas(opal.minimum_units, false)} x $
                {numberWithCommas(parseInt(opal.unit_price), false)}
                /unit
              </div>
            </div>
        </Td>
        <Td className="text-right" style={{verticalAlign: 'top'}}>
            { /*mode === 'invest' && */ getAvailability(opal)}
        </Td>
        {!share && <Td className={clsx("action-div", {"text-right" : false /*mode !== 'invest'*/})}>
            <div className={`${mode === "portfolio" ? "portfolio-top" : ""}`}>
              {mode === 'invest' && <Button
                disabled={isAvailability(opal) === 0 || isAvailability(opal) === 3}
                className={clsx("name-label", {
                  invisible: isAvailability(opal) === 2,
                  "buy-more": isBought(opal, brand),
                  "zero-subscribe": !opal.capPercent || (opal.capPercent > 0 && opal.capPercent < 25),
                  "full-subscribe": isAvailability(opal) === 3,
                  "buy-fill": (isAvailability(opal) !== 3 && opal.capPercent >= 25) && !isBought(opal, brand),
                })}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  buyHandler(opal, brand);
                }}
                title=""
              >
                {isAvailability(opal) === 3 ? 'FULLY SUBSCRIBED' : isBought(opal, brand) ? 'BUY MORE' : 'BUY'}
                {(isAvailability(opal) !== 3 && opal.capPercent >= 25) && <p className="opal-percent">{opal.capPercent ? Math.round(opal.capPercent) : 0}% full</p>}
              </Button>}
              {ria && <Image
                className="share-icon"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  shareOpalHandler(opal, brand)
                }}
                src={"/assets/images/share.svg"}
              />}
            </div>
        </Td>}
      </Tr>

      <Tr className="d-md-none" key={`${opal.opal_name}${opal.begins}${opal.end}-1`} title="See more details" onClick={() => detailHandler(brand.uid, opal.opal_name)}>
        <Td className="opal-content">
          <Image
            className="edit-icon"
            src={opal.opal_image?.url}
          />
          <div className="invester-field">
            <p className="name-label">{`${opal.opal_name}`}</p>
            {isParent && <p>{brand.data?.brand_image_title?.[0]?.text}</p>}
            <p>
              {`${opal.fund_type || ""} | ${opal.opal_highlight_label
                }`}{" "}
              <span
                className={
                  opal.gain_loss ? "text-green" : "text-red"
                }
              >{`${opal.gain_loss ? "+" : "-"} ${opal.percentage
                }%`}</span>
            </p>
            <p> </p>
            <p>
              {opal.strategy1.map(
                (item, index) => (
                  <p key={index}>{item.text}</p>
                ))}
            </p>
            {mode === 'invest' && <><p> </p>
              <div>
                $
                {numberWithCommas(parseInt(opal.unit_price) *
                  parseInt(opal.minimum_units), false)}{" "}
                minimum
              </div>
              <div>
                {numberWithCommas(opal.minimum_units, false)} x $
                {numberWithCommas(parseInt(opal.unit_price), false)}
                /unit
              </div>
              <p> </p></>}
            <p>{getAvailability(opal)}</p>

            {!share && (
              <div>
                <p> </p>
                {mode === 'invest' && <Button
                  disabled={isAvailability(opal) === 0 || isAvailability(opal) === 3}
                  className={clsx("name-label", {
                    invisible: isAvailability(opal) === 2,
                    "buy-more": isBought(opal, brand),
                    "zero-subscribe": !opal.capPercent,
                    "full-subscribe": isAvailability(opal) === 3
                  })}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    buyHandler(opal, brand);
                  }}
                  title=""
                >
                  {isAvailability(opal) === 3 ? 'FULLY SUBSCRIBED' : isBought(opal, brand) ? 'BUY MORE' : 'BUY'}
                  {(isAvailability(opal) !== 3 && opal.capPercent > 0) && <p className="opal-percent">{opal.capPercent ? Math.round(opal.capPercent) : 0}% full</p>}
                </Button>}
                {ria && <Image
                  className="share-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    shareOpalHandler(opal, brand)
                  }}
                  src={"/assets/images/share.svg"}
                />}
              </div>
            )}
          </div>
        </Td>
      </Tr>
    </>
  );

};

export default TableRow;
