import React, { useEffect, useState } from "react";
import * as prismic from "@prismicio/client";
import { navigate } from "gatsby";
import { Row, Col } from "react-bootstrap";
import { isMobile } from "../utils";
import Layout from "../components/Layout";
import TableContent from "../components/FAQ/TableContent";
import Accordion from "../components/FAQ/Accordion";
import { client as prismicClient } from "../libs/prismic";
import Loader from "../components/Loader";
import "../styles/pages/faq.scss";

export default function FAQ() {
  const [faqsDoc, setFaqsDoc] = useState();

  useEffect(() => {
    async function fetchData() {
      const response = await prismicClient().get({
        predicates: prismic.predicates.at("document.type", "faqs"),
        pageSize: 20,
      });

      // Custom sort: IDs with leading numbers come first
      response.results.sort((a, b) => {
        const regex = /^\d+/; // Regex to match leading numbers

        const aMatch = a.uid.match(regex);
        const bMatch = b.uid.match(regex);

        if (aMatch && bMatch) {
          // Both have leading numbers, compare numerically
          return parseInt(aMatch[0], 10) - parseInt(bMatch[0], 10);
        } else if (aMatch) {
          // Only `a` has a leading number, it comes first
          return -1;
        } else if (bMatch) {
          // Only `b` has a leading number, it comes first
          return 1;
        }

        // Neither has a leading number, compare alphanumerically
        return a.uid.localeCompare(b.uid);
      });

      setFaqsDoc(response);
    }
    fetchData();
  }, []);

  const handleChange = (event) => {
    if (event.target.value) {
      navigate(`#${event.target.value}`);
    }
  };
  return faqsDoc ? (
    <Layout>
      <div className="pt-80 pb-top-40 mw-1440 mx-auto justify-content-center">
        <h2 className="faq-page-title">Frequently Asked Questions</h2>
      </div>
      <Row className="pt-80 pb-top-24 mw-1440 mx-auto justify-content-center padding-mobile mb-140 faq_row">
        <Col md={5} lg={3}>
          <div
            style={{ paddingRight: 20 }}
            className="d-none d-md-block sticky_sidebar"
          >
            <TableContent items={faqsDoc.results} />
          </div>
          <div className="d-md-none sticky_sidebar">
            <select className="faq-content-select" onChange={handleChange}>
              <option>Jump to...</option>
              {faqsDoc.results?.map((item) => (
                <option value={item.uid} key={item.uid}>
                  {item.data.faq_title[0].text}
                </option>
              ))}
            </select>
          </div>
        </Col>
        <Col md={7} lg={9}>
          <div
            style={{
              paddingLeft: isMobile() ? 0 : 20,
            }}
          >
            {faqsDoc.results?.map((item, index) => (
              <div
                key={index}
                className={`faq-section ${faqsDoc?.results?.length - 1 !== index &&
                  "faq-section-separator"
                  } ${index !== 0 && "faq-section-pt"}`}
                id={item.uid}
              >
                <h2 className="faq-section-title">
                  {item.data.faq_title[0].text}
                </h2>
                <div>
                  {item.data.faq.map((faq, i) => (
                    <Accordion
                      title={faq.faq_question.map((el) => el.text).join("\n")}
                      content={faq.faq_answer}
                      key={i}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </Layout>
  ) : (
    <Loader />
  );
}
