import * as prismic from "@prismicio/client";

const apiEndpoint = process.env.GATSBY_API_END_POINT;

let _client; 
export const client = () => {
    if( !_client ) {
        _client = prismic.createClient(apiEndpoint);
    }
    return _client;
};

export const getDataByUID = async (documentType, uid) => {
  const response = await client().getByUID(documentType, uid);
  return response;
}

export const getNextData = async (documentType, postDate) => {
  const response = await client().get({
    predicates: [
      prismic.predicate.dateAfter(
        "document.first_publication_date",
        postDate
      ),
      prismic.predicate.at("document.type", documentType),
    ],
    orderings: {
      field: "document.first_publication_date",
      direction: "asc",
    },
    pageSize: 1,
  });
  return response;
}

export const getPreviousData = async (documentType, postDate) => {
  const response = await client().get({
    predicates: [
      prismic.predicate.dateBefore(
        "document.first_publication_date",
        postDate
      ),
      prismic.predicate.at("document.type", documentType),
    ],
    orderings: {
      field: "document.first_publication_date",
      direction: "desc",
    },
    pageSize: 1,
  });
  return response;
}
