import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Brand from "../../components/Brand";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import NotFoundPage from "../404";
import { client as prismicClient, getDataByUID } from "../../libs/prismic";
import { navigate } from "gatsby";
import { signup } from "../../utils/auth";

const Brands = ({ slug }) => {
  const [brandsDoc, setBrandsDoc] = useState();
  const [fundPartnerFooterData, setFundPartnerFooterData] = useState();
  const [keyMemberNames, setKeyMemberNames] = useState([]);
  const [showErrorPage, setShowErrorPage] = useState(false);
  const [blogItems, setBlogItems] = useState();
  
  let blogData;

  useEffect(() => {
    async function fetchAndSetData() {
      const blogPostDocResponse = await prismicClient().getAllByType("blog_post");
      const pressMediaDocResponse = await prismicClient().getAllByType("press_media_post");
      const docDataValue = [...blogPostDocResponse, ...pressMediaDocResponse];
      setDocData(docDataValue);
    }

    if (brandsDoc) {
      fetchAndSetData();
    }
  }, [brandsDoc])

  useEffect(() => {
    async function fetchAndSetData() {
      const fundPartnerFooterResponse = await prismicClient().getAllByType("invest");
      setFundPartnerFooterData(fundPartnerFooterResponse);
    }
    fetchAndSetData();
  })

  const setDocData = (docData) => {
    let docDataMatched;

    docDataMatched = docData.filter((item) => item?.data?.post_to_opal_fund_partner.includes(brandsDoc?.data?.brand_image_title[0].text));
    blogData = docDataMatched.map((item) => ({
      "title" : item?.data?.title[0]?.text,
      "date": new Date(item?.data?.date),
      "uid": item?.uid,
      "type": item?.type
    }));

    blogData.sort((a, b) => b.date - a.date);
    blogData.splice(3);
    setBlogItems(blogData);
  }

  useEffect(() => {
    async function fetchData(slug) {
      try {
        const response = await getDataByUID("opal_fund_partners", slug);
        if (response) {
          setBrandsDoc(response);
        }
      } catch (error) {
        setShowErrorPage(true);
      }
    }
    if (slug) {
      fetchData(slug);
    }
  }, [slug]);

  useEffect(async () => {
    if (brandsDoc?.data?.brand_key_members) {
      setKeyMemberNames(brandsDoc?.data?.brand_key_members.map((item) => item?.text))
    }
  }, [brandsDoc]);
  
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const handleNavigate = () => {
    navigate('/signup');
  };
  return brandsDoc ? (
    <Layout>
      <Brand
        cardImage='/assets/images/cityscape.png'
        image={brandsDoc && brandsDoc?.data?.brand_image?.url}
        heading1={brandsDoc && brandsDoc?.data?.brand_heading_1?.[0]?.text}
        description={brandsDoc && brandsDoc?.data?.brand_description_1?.[0]?.text}
        imageTitle={brandsDoc && brandsDoc?.data?.brand_image_title[0]?.text}
        amount={brandsDoc && brandsDoc?.data?.brand_amount[0]?.text}
        keyMembers={brandsDoc && keyMemberNames}
        address={brandsDoc && brandsDoc?.data?.brand_address[0]?.text}
        blogItems={blogItems}
        className="mb-120"
      >
        <p className="oplevest"> {fundPartnerFooterData[0].data.prefooter[0].text}</p>
        <p className="sub-brand-description">
          {fundPartnerFooterData[0].data.prefooter[1].text}
        </p>
        <Button
          variant="dark"
          className="get-started-btn"
          onClick={handleNavigate}
        >
          {fundPartnerFooterData[0].data.prefooter_button_text[0].text}
        </Button>
      </Brand>
    </Layout>
  ) : showErrorPage ? (
    <NotFoundPage />
  ) : (
    <Loader />
  );
};

export default Brands;
