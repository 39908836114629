import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import PressAndMediaDetailsComponent from "../../components/PressAndMedia/Details";
import { getDataByUID, getNextData, getPreviousData } from "../../libs/prismic";
import NotFoundPage from "../404";

const PressMediaDetails = ({ slug }) => {
  const [pressMediaDoc, setPressAndMedia] = useState();
  const [postDate, setPostDate] = useState();
  const [showErrorPage, setShowErrorPage] = useState(false);

  async function fetchData(uid) {
    try {
      const response = await getDataByUID("press_media_post", uid);
      if (response) {
        setPressAndMedia(response);
        setPostDate(response.first_publication_date);
      }
    } catch (error) {
      setShowErrorPage(true);
    }
  }

  async function fetchNextData(postDate) {
    try {
      const response = await getNextData("press_media_post", postDate);
      if (response.results.length > 0) {
        setPressAndMedia((prev) => ({
          ...prev,
          next: response.results[0],
        }));
      }
    } catch (error) {
      setShowErrorPage(true);
    }
  }

  async function fetchPreviousData(postDate) {
    try {
      const response = await getPreviousData("press_media_post", postDate);
      if (response.results.length > 0) {
        setPressAndMedia((prev) => ({
          ...prev,
          previous: response.results[0],
        }));
      }
    } catch (error) {
      setShowErrorPage(true);
    }
  }

  useEffect(() => {
    if (slug) {
      fetchData(slug);
    }
  }, [slug]);

  useEffect(() => {
    if (postDate) {
      fetchNextData(postDate);
      fetchPreviousData(postDate);
    }
  }, [postDate]);

  return pressMediaDoc ? (
    <Layout>
      <PressAndMediaDetailsComponent
        slices={pressMediaDoc.data?.body}
        title={pressMediaDoc.data?.title?.[0]?.text}
        subtitle={pressMediaDoc.data?.subtitle?.[0]?.text}
        author={pressMediaDoc.data?.author?.[0]?.text}
        date={pressMediaDoc.data?.date}
        category={pressMediaDoc.data?.category}
        nextSlug={pressMediaDoc.next?.uid}
        nextBlog={pressMediaDoc.next?.data?.title?.[0]?.text}
        previousSlug={pressMediaDoc.previous?.uid}
        previousBlog={pressMediaDoc.previous?.data?.title?.[0]?.text}
      />
    </Layout>
  ) : showErrorPage ? (
    <NotFoundPage />
  ) : (
    <Loader />
  );
};

export default PressMediaDetails;
