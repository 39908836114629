import { Link } from "gatsby";
import { RichText } from "prismic-reactjs";
import { useLocation } from '@reach/router';
import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { client as prismicClient } from "../../libs/prismic";

const Footer = () => {
  const [footerDoc, setFooterDoc] = useState();
  const [commonDoc, setCommonDoc] = useState(false);
  const location = useLocation();

  useEffect(() => {
    async function fetchData() {
      const response = await prismicClient().getSingle("footer");
      setFooterDoc(response);

      const response1 = await prismicClient().getSingle("common_section");
      setCommonDoc(response1);
    }
    fetchData();
  }, []);
  return (
    <div className="footer">
      <div className="mw-1440 mx-auto">
        {(!location.href.includes("share-opal/") && !location.href.includes("document-sign/")) && <div className="footer-container">
          <div>
            <ul className="footer-menu" style={{ paddingTop: 0 }}>
              <li style={{ paddingLeft: 0 }}>
                <Link to="/full-disclosure/" className="disclouser">
                  Full Disclosure
                </Link>
              </li>
              |
              <li>
                <Link to="/privacy-policy/" className="disclouser">
                  Privacy Policy
                </Link>
              </li>
              <span className="d-xs-none">|</span>
              <li>
                <Link to="/cookie-settings/" className="disclouser">
                  Cookie Settings
                </Link>
              </li>
              <span className="line_after_cookie"> | </span>
              <li>
                <Link to="/accessibility/" className="disclouser">
                  Accessibility
                </Link>
              </li>
              |
              <li>
                <Link to="/terms-of-use/" className="disclouser">
                  Terms of Use
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <ul className="footer-menu">
              <li>
                <Link to="/about-us/" className="disclouser">
                  About & Team
                </Link>
              </li>
              |
              <li>
                <Link to="/partner-with-us/" className="disclouser">
                  Partner with us
                </Link>
              </li>
            </ul>
          </div>
        </div>}
        <div className="footer-details">
          {(location.href.includes("document-sign/") || location.href.includes("share-opal/")) ? 
          <RichText render={footerDoc && footerDoc?.data["ria_sub-client_footer"]} /> :
          <RichText render={footerDoc && footerDoc?.data?.footer_description} />}
        </div>
        <div className="copyrights">
          <p style={{ color: "#ffff", marginTop: 20 }}>
            <RichText render={footerDoc && footerDoc?.data?.copyright_text} />
          </p>
          <Image
            src={commonDoc && commonDoc?.data?.footer_logo?.url}
            className="footer-img"
            alt=""
            fluid
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
