import * as prismic from "@prismicio/client";
import { RichText } from "prismic-reactjs";
import React, { useEffect, useState } from "react";
import { Col, Container, Button, Row } from "react-bootstrap";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import BlogComponent from "../../components/Blog/Card";
import { client as prismicClient } from "../../libs/prismic";
import "../../styles/pages/press-and-media.scss";

const PressAndMedia = () => {
  const [isLoading, setLoading] = useState(false);
  const [pressMediaDoc, setPressAndMedia] = useState();
  const [pressMediaListDoc, setPressMediaList] = useState([]);
  const [pageInfo, setPageInfo] = useState({
    page: 1,
    pageSize: 5,
    totalPages: 0,
  });

  async function fetchData(_pageInfo) {
    try {
      setLoading(true);
      const response = await prismicClient().getSingle("press_and_media");
      setPressAndMedia(response);
      const listResponse = await prismicClient().get({
        predicates: prismic.predicates.at("document.type", "press_media_post"),
        pageSize: _pageInfo.pageSize,
        page: _pageInfo.page,
        orderings: {
          field: "document.first_publication_date",
          direction: "desc",
        },
      });

      listResponse?.results.forEach((item) => {
        item.dateTimestamp = new Date(item?.data?.date).getTime();
        for (let element of item?.data?.body) {
          if (element.slice_type === "image") {
            item.featureImage = element?.primary?.image?.url;
            item.featureDescription =
              element?.primary?.top_text[0]?.text ||
              element?.primary?.bottom_text[0]?.text;
            break;
          }
        }
      });
      setPressMediaList((pressMediaListDoc) => [
        ...pressMediaListDoc,
        ...(listResponse?.results || []),
      ]);
      setPageInfo({
        page: listResponse.page,
        pageSize: listResponse.results_per_page,
        totalPages: listResponse.total_pages,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData(pageInfo);
  }, []);

  const loadmoreHandler = () => {
    if (pageInfo.totalPages === pageInfo.page) return;
    fetchData({
      ...pageInfo,
      page: pageInfo.page + 1,
    });
  };

  return !isLoading ? (
    <Layout>
      <Row className="pt-80 pb-top-40 mb-120">
        <Col md={12}>
          <Container>
            <div className="press-media-banner rich-text text-center text-md-start">
              <RichText render={pressMediaDoc?.data?.banner_section} />
            </div>

            <Col className="press-media-separator">
              <hr />
            </Col>
            {pressMediaListDoc
            .sort((a, b) => new Date(b.data.date) - new Date(a.data.date))
            .map((item, index, press) => {
              return (
                <BlogComponent
                  key={index}
                  title={item?.data?.title[0]?.text}
                  author={item?.data?.author[0]?.text}
                  excerpt={item?.featureDescription}
                  imageUrl={item?.featureImage}
                  category={item?.data?.category}
                  date={item?.data?.date}
                  navigation={`/press-and-media/${item?.uid}`}
                  isLast={
                    press.length - 1 === index &&
                    pageInfo.totalPages === pageInfo.page
                  }
                />
              );
            })}
            {pageInfo.totalPages !== pageInfo.page && (
              <div className="blog_loadmore">
                <Button
                  variant="outline-secondary"
                  onClick={loadmoreHandler}
                  className="blog_loadmore_btn"
                >
                  Load More
                </Button>
              </div>
            )}
          </Container>
        </Col>
      </Row>
    </Layout>
  ) : (
    <Loader />
  );
};

export default PressAndMedia;
