import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Brand from "../../components/Brand";
import SSRProvider from 'react-bootstrap/SSRProvider';
import Loader from "../../components/Loader";
import { client as prismicClient, getDataByUID } from "../../libs/prismic";
import { Col, Row } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th } from "../../components/Table";
import TableRow from "../../components/InvestTrade/PageContent/TableRow";
import Footer from "../../components/Footer";
import { isMobile, formatPhoneNumber } from "../../utils";
import { isAfter5days, sortOpals } from "../../utils/validationFunc";
import "../../styles/components/InvestTrade/PageContent/style.scss";
import "../../styles/pages/investtrade.scss";
import OpalFile from "../../components/InvestTrade/PageContent/OpalFile";
import {
  actionTypes,
} from "../../store/Dialog/type";
import Dialog from "../../components/Dialog";


// styles
const pageStyles = {
  margin: 0,
  padding: 0,
  marginBottom: '80px',
}

const headerStyles = {
  height: '95px',
  background: '#1F3E78',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '40px',
  display: 'flex',
  justifyContent: 'space-between'
}

const logoStyle = {
  left: '60px',
  width: '240px',
  height: '54px',
  top: '85px',
}

const ShareOpal = ({
  slug,
  location,
}) => {
  const [brandsDoc, setBrandsDoc] = useState();
  const [opals, setOpals] = useState([]);
  const [keyMemberNames, setKeyMemberNames] = useState([]);
  const [blogItems, setBlogItems] = useState();

  const params = new URLSearchParams(location.search);
  const opalName = params.get("opal");
  const ref = params.get('ref');

  const refRiaUser = ref ? JSON.parse(atob(ref)) : null;

  const dispatch = useDispatch();

  const parentAccounts = useSelector(
    (state) => state?.accounts?.parentAccounts
  );

  const user = useSelector(
    (state) => state?.app?.profile
  );

  const ria = parentAccounts?.[0]?.type === 'ria'
  console.log("ria", ria)

  let blogData;

  useEffect(() => {
    async function fetchData(slug) {
      try {

        const response = await getDataByUID("opal_fund_partners", slug);
        if (response) {
          setBrandsDoc(response);
        }
      } catch (error) {
      }
    }
    if (slug) {
      fetchData(slug);
    }
  }, [slug]);

  useEffect(() => {
    console.log('user', user);

  }, [user])

  useEffect(() => {
    async function fetchAndSetData() {
      const blogPostDocResponse = await prismicClient().getAllByType("blog_post");
      const pressMediaDocResponse = await prismicClient().getAllByType("press_media_post");
      const docDataValue = [...blogPostDocResponse, ...pressMediaDocResponse];
      setDocData(docDataValue);
    }

    if (brandsDoc) {
      fetchAndSetData();
    }
  }, [brandsDoc])

  const setDocData = (docData) => {
    let docDataMatched;
    docDataMatched = docData.filter((item) => item?.data?.post_to_opal_fund_partner.includes(brandsDoc?.data?.brand_image_title[0].text));
    
    blogData = docDataMatched?.map((item) => ({
      "title" : item?.data?.title[0]?.text,
      "date": new Date(item?.data?.date),
      "uid": item?.uid,
      "type": item?.type
    }));

    blogData?.sort((a, b) => b.date - a.date);
    blogData?.splice(3);
    setBlogItems(blogData);
  }

  useEffect(async () => {
    if (brandsDoc?.data?.brand_key_members) {
      setKeyMemberNames(brandsDoc?.data?.brand_key_members.map((item) => item?.text))
    }
  }, [brandsDoc]);
  
  useEffect(() => {
    if (brandsDoc) {
      let _opals = [];
      brandsDoc.data?.opals.forEach((opal) => {
        if (isAfter5days(opal) || !opal.display) return;

        if (opal.opal_name === opalName) {
          _opals.push({ opal });
        }
      });

      _opals = sortOpals(_opals);
      setOpals(_opals);
    }
  }, [opalName, brandsDoc])
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const mediaHandler = (url, label, opal_name) => {
    if (url.split('.').pop() === 'pdf') {
      let new_window;

      new_window = window.open('', '_blank', 'toolbar=0,location=0,menubar=0,popup=1,titlebar=0,location=0');

      let html_contents = `<embed src="${url}" style="width: 100%; height: 100vh"/>`;

      new_window.document.write('<!doctype html><html><head><title></title><meta charset="UTF-8" /><meta name="viewport" content="width=device-width"></head><body style="background-color: rgb(0, 0, 0);margin:0px">' + html_contents + '</body></html>');

    } else {
      dispatch({ type: actionTypes.SHOW_DIALOG, value: { type: 'dialog_video', show: true, payment: null, data: {opal_name, label, url} } });
    }
  }

  return (
    <SSRProvider>
      <main style={pageStyles}>
        <div style={headerStyles}>
          <div style={logoStyle}><img height="54px" src="https://images.prismic.io/opalvest/38c7b4e5-807b-4d67-90cf-6514bb473a0c_ov-knockout-logo-108px-w.png?auto=compress,format" alt="" /></div>
          {refRiaUser && (
            <div className="ria-container-header d-none d-md-flex">
              <div className="investor-label">Registered<br/>Investment<br/>Advisor</div>
              <div className="investor-info">
                <b>{`${refRiaUser.name}`}</b><br />
                <div className="entity-info-detail">
                  {refRiaUser.company} <br />
                  <div>
                    {refRiaUser.phone}
                  </div>
                </div>
              </div>
            </div>
          )}              
          {!refRiaUser && ria && (
            <div className="ria-container-header d-none d-md-flex">
              <div className="investor-label">Registered<br/>Investment<br/>Advisor</div>
              <div className="investor-info">
                <b>{`${user.firstName} ${user.lastName}`}</b><br />
                <div className="entity-info-detail">
                  {parentAccounts[0].entityName} <br />
                  <div>
                    {formatPhoneNumber(parentAccounts[0].phoneNo)}
                  </div>
                </div>
              </div>
            </div>
          )}
     
        </div>
        {!brandsDoc ? <Loader /> : <>
          <Brand
            image={brandsDoc && brandsDoc?.data?.brand_image?.url}
            heading1={brandsDoc && brandsDoc?.data?.brand_heading_1[0]?.text}
            description={brandsDoc && brandsDoc?.data?.brand_description_1[0]?.text}
            imageTitle={brandsDoc && brandsDoc?.data?.brand_image_title[0]?.text}
            amount={brandsDoc && brandsDoc?.data?.brand_amount[0]?.text}
            keyMembers={brandsDoc && keyMemberNames}
            address={brandsDoc && brandsDoc?.data?.brand_address[0]?.text}
            blogItems={blogItems}
            className="mb-62"
          />
          <Row className="justify-content-center mx-auto mw-1440 investtrade_content">
            <Col>
              <Col md={12} className="d-none d-md-block">
                <Table className="responsiveTable">
                  <Thead>
                    <Tr>
                      <Th style={{ width: '30%' }}>Initial Opal Offerings</Th>
                      <Th style={{ width: '30%' }}>Strategy</Th>
                      <Th style={{ width: '15%' }} className="text-right">Minimum </Th>
                      <Th style={{ width: '10%', minWidth: '124px' }} className="text-right">Availability</Th>
                    </Tr>
                  </Thead>
                </Table>
                {opals?.map((item, index) => {
                  const isWithBreakItem = opals.length > 1 && index === 1
                  return (
                    <>
                      <table className={`opal-file-table ${isWithBreakItem ? 'break-line' : ''}`}>
                        <thead>
                          <Tr className="no-border">
                            <Th style={{ width: '30%' }}></Th>
                            <Th style={{ width: '30%' }}></Th>
                            <Th style={{ width: '15%' }} className="text-right"></Th>
                            <Th style={{ width: '10%', minWidth: '124px' }} className="text-right"></Th>
                          </Tr>
                        </thead>
                        <tbody>
                          <TableRow
                            key={index}
                            brand={brandsDoc}
                            opal={item.opal}
                            mediaHandler={mediaHandler}
                            share={true}
                          />
                        </tbody>
                      </table>

                      <OpalFile
                        key={index}
                        brand={brandsDoc}
                        opal={item.opal}
                        mediaHandler={mediaHandler}
                      />
                    </>
                  )
                })}
              </Col>

              <Col md={12} className="initial-offerings d-md-none">
                <b>Initial Opal Offerings</b>
              </Col>
              <Col md={12} className="d-md-none">
                <Table>
                  <Tbody>
                    {opals?.map((item, index) => {
                      return (
                        <>
                          <TableRow
                            key={index}
                            brand={brandsDoc}
                            opal={item.opal}
                            mediaHandler={mediaHandler}
                            share={true}
                          />
                          <OpalFile
                            key={index}
                            brand={brandsDoc}
                            opal={item.opal}
                            mediaHandler={mediaHandler}
                          />
                        </>
                      )
                    })}
                  </Tbody>
                </Table>
              </Col>
            </Col>
          </Row>
        </>}
      </main>
      <Footer />
      <Dialog />
    </SSRProvider>)
};

export default ShareOpal;
