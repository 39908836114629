import * as prismic from "@prismicio/client";
import { RichText } from "prismic-reactjs";
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import BlogComponent from "../../components/Blog/Card";
import BlogCatetory from "../../components/Blog/Category";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import { client as prismicClient } from "../../libs/prismic";
import "../../styles/pages/blog.scss";

const Blog = () => {
  const [isLoading, setLoading] = useState(false);
  const [blogDoc, setBlogDoc] = useState();
  const [blogListDoc, setBlogListDoc] = useState([]);
  const [categories, setCategries] = useState(["Blog Home"]);
  const [selectedCategory, setSelectedCategory] = useState("Blog Home");
  const [pageInfo, setPageInfo] = useState({
    page: 1,
    pageSize: 5,
    totalPages: 0,
  });

  async function fetchData(_pageInfo) {
    try {
      setLoading(true);
      const response = await prismicClient().getSingle("blog");
      setBlogDoc(response);
      const listResponse = await prismicClient().get({
        predicates: prismic.predicates.at("document.type", "blog_post"),
        pageSize: _pageInfo.pageSize,
        page: _pageInfo.page,
        orderings: {
          field: "document.first_publication_date",
          direction: "desc",
        },
      });

      let _categories = [...categories];
      listResponse?.results.forEach((item) => {
        if (item?.data.category) {
          _categories.push(item?.data.category);
        }
        item.dateTimestamp = new Date(item?.data?.date).getTime();
        for (let element of item?.data?.body) {
          if (element.slice_type === "image") {
            item.featureImage = element?.primary?.image?.url;
            break;
          }
        }
      });

      _categories = _categories.filter(
        (v, i, a) =>
          a.indexOf(v) === i && v !== undefined && v !== null && v !== ""
      );
      setCategries(_categories);

      setBlogListDoc((blogListDoc) => [
        ...blogListDoc,
        ...(listResponse?.results || []),
      ]);

      setPageInfo({
        page: listResponse.page,
        pageSize: listResponse.results_per_page,
        totalPages: listResponse.total_pages,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchData(pageInfo);
  }, []);

  const loadmoreHandler = () => {
    if (pageInfo.totalPages === pageInfo.page) return;
    fetchData({
      ...pageInfo,
      page: pageInfo.page + 1,
    });
  };
  return !isLoading ? (
    <Layout>
      <Row className="pt-80 pb-top-40 mb-120 px-0">
        <Col md={12}>
          <Container>
            <div className="blog_banner rich-text text-center text-md-start">
              <RichText render={blogDoc?.data?.banner_section} />
            </div>
            <BlogCatetory
              data={categories}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            >
              {blogListDoc
                .filter(
                  (item) =>
                    selectedCategory === "Blog Home" ||
                    item?.data?.category === selectedCategory
                )
                .sort((a, b) => new Date(b.data.date) - new Date(a.data.date))
                .map((item, index, blogs) => {
                  return (
                    <div key={index}>
                      <BlogComponent
                        key={index}
                        title={item?.data?.title[0]?.text}
                        author={item?.data?.author[0]?.text}
                        excerpt={item?.data?.excerpt[0]?.text}
                        imageUrl={item?.featureImage}
                        date={item?.data?.date}
                        category={item?.data?.category}
                        navigation={`/blog/${item?.uid}`}
                        isLast={
                          blogs.length - 1 === index &&
                          pageInfo.totalPages === pageInfo.page
                        }
                      />
                    </div>
                  );
                })}
            </BlogCatetory>
            {pageInfo.totalPages !== pageInfo.page && (
              <div className="blog_loadmore">
                <Button
                  variant="outline-secondary"
                  className="blog_loadmore_btn"
                  onClick={loadmoreHandler}
                >
                  Load More
                </Button>
              </div>
            )}
          </Container>
        </Col>
      </Row>
    </Layout>
  ) : (
    <Loader />
  );
};

export default Blog;
